body {
    margin: 0;
    font-family: Source Sans Pro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.reactour__popover {
    padding: 0px !important;
    border-radius: 10px;
}
