div.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    height: 100vh;
}

@media screen and (max-width: 600px) {
    .main-content {
        margin-left: 0;
    }
}

body {
    background-color: #f7f8fa;
}

/* Reduce nav bar width to the size of drawerMarginLeft */
.MuiAppBar-positionFixed {
    left: 0 !important;
}
